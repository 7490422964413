import { Link } from "react-router-dom";
import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Mainnet",
        ribbion: "Mainnet",
        class: "mainnet-hover",
        span: "span-white",
        description: 'The BlockStar Mainnet is the heart of live blockchain functionality. Designed for secure and scalable performance, it’s the fully decentralized infrastructure powering real-world applications, transactions, and groundbreaking blockchain innovation.',
        link: "https://scan.blockstar.one/",
        link_type: 1
    },
    {
        header: "BlockStar Testnet",
        ribbion: "Testnet",
        class: "testnet-hover",
        span: "span-white",
        description: 'Test with confidence on the BlockStar Testnet. Built for developers, it delivers a risk-free environment to refine protocols, optimize smart contracts, and perfect your blockchain projects before going live on the Mainnet.',
        link: "https://testnet-scan.blockstar.one/",
        link_type: 1
    }
];


export default function Chain() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                item.link_type === 2 ? (
                                    <Link
                                        to={item.link}
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </Link>
                                ) : (


                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </a>
                                )
                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
