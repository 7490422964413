import { Link } from "react-router-dom";
import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Launchpad",
        ribbion: "Launchpad",
        class: "launchpad-hover",
        span: "span-crypto",
        description: 'BlockStar Launchpad brings creators and global investors together to fund, launch, and scale innovative blockchain projects. Build trust, secure critical resources, and grow your community from the very start.',
        link: "https://launchpad.blockstar.site/",
        link_type: 1
    },
    {
        header: "BlockStar NFT",
        ribbion: "NFT",
        class: "nft-hover",
        span: "span-crypto",
        description: 'BlockStar NFT empowers creators to mint, trade, and showcase digital assets. From art and music to gaming and collectibles, it provides a frictionless and secure experience, and a vibrant marketplace for global users.',
        link: "https://blockstar.site/nft",
        link_type: 1
    },
    {
        header: "BlockStar Staking",
        ribbion: "Staking",
        class: "staking-hover",
        span: "span-crypto",
        description: 'Maximize your digital assets with BlockStar’s Crypto. Engage in secure staking opportunities and watch your cryptocurrency work for you. Simple tools, transparent processes, and steady rewards - that’s the power of staking with BlockStar.',
        link: "https://blockstar.site/staking",
        link_type: 1
    },
    {
        header: "BlockStar DEX",
        ribbion: "DEX",
        class: "dex-hover",
        span: "span-crypto",
        description: 'Swap, earn, and build on the leading decentralized crypto platform, designed for unmatched efficiency and simplicity. With BlockStar DEX, enjoy lightning-fast trades, robust security, and complete user control over your digital assets.',
        link: "https://dex.blockstar.site/",
        link_type: 1
    },
    {
        header: "BlockStar Synergy",
        ribbion: "Synergy",
        class: "synergy-hover",
        span: "span-crypto",
        description: 'Effortless token creation—no coding needed. Launch tokens on multiple chains and testnets in just a few steps. Leverage multi-send functionality to streamline distribution. From testing to launches, Synergy keeps it simple, scalable, and secure.',
        link: "http://synergy.blockstar.site/",
        link_type: 1
    },
    {
        header: "BlockStar DAO",
        ribbion: "DAO",
        class: "dao-hover",
        span: "span-crypto",
        description: 'From proposals to collective decisions, BlockStar DAO is the engine that lets the BlockStar ecosystem thrive on decentralized collaboration. Governed by the community, it gives users a space to contribute, vote, and engage with the ecosystem\'s growth.',
        link: "https://blockstar.site/dao/",
        link_type: 1
    },
];


export default function DeFi() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                item.link_type === 2 ? (
                                    <Link
                                        to={item.link}
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </Link>
                                ) : (


                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </a>
                                )
                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
