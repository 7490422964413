import "../styles/Ecosystem.scss";

const ecosystemArray = [
    {
        header: "BlockStar Tv",
        ribbion: "Tv",
        class: "tv-hover",
        span: "span-tv",
        description: 'The venue for decentralized video where users can stream video, rent movies, and enjoy user-generated content. Earn points for watching, commenting, uploading, and sharing your video files. Convert to $BST whenever you like.',
        link: "https://blockstar.tv/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Digital",
        ribbion: "Digital",
        class: "digital-hover",
        span: "span-digital",
        description: 'Decentralized audio streaming service where users can upload, listen, share, and comment on music, podcasts, and audiobooks. Artists and users can earn points for their activity and then convert to $BST whenever they like.',
        link: "https://blockstar.digital/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Channels",
        ribbion: "Channels",
        class: "channels-hover",
        span: "span-utility",
        description: 'BlockStar Channels is your all-in-one destination for non-stop streaming. Access thousands of live streams alongside full episodes of your favorite shows—delivering entertainment, connection, and convenience like never before.',
        link: "https://blockstar.center/channel/",
        is_new: 2,
        link_type: 1
    }
];


export default function Entertainment() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (


                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    {/* {item.is_new === 1 && */}
                                    {/* <div class="ribbon ribbon1  ribbon-bottom-right">
                                        <span className={item.span}></span>
                                    </div> */}
                                    {/* } */}
                                    
                                    <p>{item.description}</p>
                                </a>



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
